.variable-width .slick-slide p {
  background: #00558B;
  height: 100px;
  color: #fff;
  margin: 5px;
  line-height: 100px;
  text-align: center;
}
.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}
.center h3{
  opacity: 0.8;
  transition: all 300ms ease;
}
.content {
  padding: 20px;
  margin: auto;
}
@media (min-width: 701px) {
  .content {
    width: 80%;
  }
}
@media (max-width: 700px) {
  .content {
    width: 70%;
  }
}

.slick-slider {
  margin: 30px auto 110px;
}

.slick-slide .image {
  padding: 10px;
}
.slick-slide img {
  border: 5px solid #FFF;
  display: block;
  margin: auto;
  max-width: 80%;
}
.slick-slide img.slick-loading {
  border: 0
}
.slick-slider {
  margin: 30px auto 100px;
}
.slick-dots {
  margin-left: 0;
}
.slick-thumb {
  bottom: -90px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.slick-thumb li {
  width: 50px;
  height: 40px;
}
.slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.slick-thumb li.slick-active img{
    filter: grayscale(0%);
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: black;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 768px) {
  h3 {
    font-size:24px;
  }
  .center {
    margin-left: -40px;
    margin-right: -40px;
  }
  .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1);
  }
  .center h3 {
    opacity: 0.8;
    transform: scale(0.95);
    transition: all 300ms ease;
  }
  .slick-thumb {
    bottom: -180px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

@media screen and (max-width: 480px) { 
  .slick-slider {
    margin: 30px auto 200px;
  }

  .slick-thumb {
    bottom: -170px;
  }
  .slick-thumb li {
    width: 40px;
    height: 30px;
  }

}

.slick-vertical .slick-slide {
  height: 180px;
}
.slick-arrow {
  background-color: white;
}
.slick-arrow:hover {
  background-color: white;
}
.slick-arrow:focus {
  background-color: white;
}
.button {
  background-color: #00558B;
  padding: 10px 20px;
  margin: 0px 20px;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  min-height: 45px
}
